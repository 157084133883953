/**
 * Utility module for <detail> elements that should only appear one at a time.
 */

const soleDetails = (): Element[] =>
  Array.from(document.querySelectorAll(".js-sole-detail"));

soleDetails().map((soleDetail) =>
  soleDetail.querySelector("summary").addEventListener("click", () =>
    soleDetails()
      .filter((target) => target !== soleDetail)
      .filter((target) => target.contains(soleDetail) === false)
      .map((target) => target.removeAttribute("open"))
  )
);
