import { disableBodyBlur, enableBodyBlur } from "../utils/blur";

/** All AdvisorSpeak elements. */
const advisorSpeaks = (): NodeListOf<HTMLDetailsElement> =>
  document.querySelectorAll(".js-advisor-speak");

const setupToggleListener = (advisorSpeak: HTMLDetailsElement): void =>
  advisorSpeak.addEventListener("toggle", () => {
    if (advisorSpeak.open) {
      enableBodyBlur();
    } else {
      disableBodyBlur();
    }
  });

Array.from(advisorSpeaks()).map(setupToggleListener);
