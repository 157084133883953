class NestedLinks {
  constructor() {
    document.body.addEventListener("click", (event) => this.handleClick(event));
  }

  handleClick(event: Event) {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const nestedLink = this.getNestedLink(event.target);

    if (nestedLink && !this.selection) {
      (nestedLink.querySelector("a, button") as HTMLElement).click();
    }
  }

  getNestedLink(element: HTMLElement) {
    return element.closest(".js-nested-link");
  }

  get selection() {
    return window.getSelection()?.toString();
  }
}

export default new NestedLinks();
