/** All links on the page. */
const getLinks = (): NodeListOf<HTMLAnchorElement> => {
  return document.querySelectorAll("a");
};

/** Check if a link is for an external website. */
const isExternalLink = (link: HTMLAnchorElement): boolean => {
  return link.hostname !== window.location.hostname;
};

/** Check if a link is not excluded from opening in a new tab. */
const isNotExcludedLink = (link: HTMLAnchorElement): boolean =>
  link.classList.contains("js-external-link-exclude") === false;

/** Make a link open in a new tab. */
const addExternalAttributes = (link: HTMLAnchorElement): HTMLAnchorElement => {
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");

  return link;
};

export default Array.from(getLinks())
  .filter(isExternalLink)
  .filter(isNotExcludedLink)
  .map(addExternalAttributes);
