import { disableBodyBlur, enableBodyBlur } from "../utils/blur";

/** All header navigation toggle elements. */
const navToggles = (): NodeListOf<HTMLElement> =>
  document.querySelectorAll(".js-header-nav-toggle");

/** The single header navigation element. */
const nav = (): HTMLElement => document.querySelector(".js-header-nav");

/** Create an event listener for a navigation toggle element. */
const setupNavToggleListener = (navToggle: HTMLElement): void =>
  navToggle.addEventListener("click", (): void => {
    const isOpen = nav().classList.contains("is-open");
    nav().classList.toggle("is-open", !isOpen);

    if (isOpen) {
      disableBodyBlur();
    } else {
      enableBodyBlur();
    }
  });

export default Array.from(navToggles()).map(setupNavToggleListener);
