/** All annotated assets on the page. */
const annotatedAssets = (): NodeListOf<HTMLElement> =>
  document.querySelectorAll(".js-annotated-asset");

/** The media for an annotated asset. */
const annotatedAssetImage = (
  annotatedAsset: HTMLElement
): HTMLImageElement | HTMLVideoElement =>
  annotatedAsset.querySelector(".js-annotated-asset-media");

/** Make media visible on load. */
const removeInvisibleOnLoad = (media: HTMLImageElement | HTMLVideoElement) => {
  /** Find the closest invisible class and remove it. */
  const removeParentClass = (media: HTMLImageElement | HTMLVideoElement) =>
    media.closest(".invisible")?.classList.remove("invisible");

  if (media instanceof HTMLImageElement) {
    if (media.complete) {
      removeParentClass(media);
    } else {
      media.addEventListener("load", () => removeParentClass(media));
    }
  }

  if (media instanceof HTMLVideoElement) {
    media.addEventListener("loadeddata", () => removeParentClass(media));

    if (media.poster) {
      const image = new Image();
      image.addEventListener("load", () => removeParentClass(media));
      image.src = media.poster;
    }
  }

  return media;
};

export default Array.from(annotatedAssets())
  .map(annotatedAssetImage)
  .map(removeInvisibleOnLoad);
